import * as React from "react";
import { UitkImage } from "@egds/react-core/images";
import { Viewport, ViewSmall, ViewMedium, ViewLarge } from "@shared-ui/viewport-context";
import { ImageProperties } from "src/components/flexComponents/Hotels/components/SummarizedHotelCard/helpers/image-properties";

/**
 * Image component that leverages Akamai Image Manager (AIM).
 * Documentation: https://confluence.expedia.biz/display/COPL/Content+Platform+Media+Image+Derivatives
 */

type HeightForRatio = (width: number) => number;
const calculateHeight = (aspectRatio: number): HeightForRatio => (width) => Math.round(width / aspectRatio);

const RatiosMap = {
  "16-9": {
    value: 16 / 9,
    getHeight: calculateHeight(16 / 9),
    getWidthByQuality: {
      low: 400,
      medium: 600,
      high: 900,
    },
  },
  "2-1": {
    value: 2,
    getHeight: calculateHeight(2),
    getWidthByQuality: {
      low: 200,
      medium: 400,
      high: 600,
    },
  },
  "4-1": {
    value: 4,
    getHeight: calculateHeight(4),
    getWidthByQuality: {
      low: 400,
      medium: 600,
      high: 900,
    },
  },
  "21-9": {
    value: 21 / 9,
    getHeight: calculateHeight(21 / 9),
    getWidthByQuality: {
      low: 400,
      medium: 600,
      high: 900,
    },
  },
  "3-2": {
    value: 3 / 2,
    getHeight: calculateHeight(3 / 2),
    getWidthByQuality: {
      low: 300,
      medium: 600,
      high: 900,
    },
  },
  "5-1": {
    value: 5,
    getHeight: calculateHeight(5),
    getWidthByQuality: {
      low: 400,
      medium: 600,
      high: 900,
    },
  },
  "1-1": {
    value: 1,
    getHeight: calculateHeight(1),
    getWidthByQuality: {
      low: 100,
      medium: 200,
      high: 300,
    },
  },
  "3-4": {
    value: 3 / 4,
    getHeight: calculateHeight(3 / 4),
    getWidthByQuality: {
      low: 100,
      medium: 200,
      high: 300,
    },
  },
  "4-3": {
    value: 4 / 3,
    getHeight: calculateHeight(4 / 3),
    getWidthByQuality: {
      low: 200,
      medium: 400,
      high: 600,
    },
  },
};
type ImageAspectRatio = keyof typeof RatiosMap;
const DEFAULT_RATIO: ImageAspectRatio = "16-9";

/**
 * Contract used to determine the dimension of an Image.
 */
interface ImageDimension {
  width: number;
  height: number;
}

/**
 * Maps a small set of qualities provided by Akamai Image Manager.
 */
export const aimQualityMap = {
  low: "mediumLow",
  medium: "medium",
  high: "mediumHigh",
};

export type ImageQuality = keyof typeof aimQualityMap;

const ImageComponentByQuality = (quality: ImageQuality) => {
  return (props: BlossomImageProps) => {
    const {
      src,
      cropDimension: dimension,
      imageRatio,
      quality: overrideQuality,
      widthLarge,
      widthMedium,
      widthSmall,
      noCrop,
    } = props;

    const widthSizeByQuality = {
      low: widthSmall,
      medium: widthMedium,
      high: widthLarge,
    };

    const overrideWidth = widthSizeByQuality[quality];
    const qualityToUse = overrideQuality ?? quality;
    const resolvedRatio = (imageRatio || DEFAULT_RATIO) as ImageAspectRatio;
    const ratioDimension = dimension || dimensionForRatio(resolvedRatio, qualityToUse, overrideWidth);
    return <UitkImage {...props} src={cropResizeSrc(src, ratioDimension, qualityToUse, noCrop)} />;
  };
};

/**
 * Implementation of the Low Quality Image. Smaller image and lower quality good for small devices.
 * @param props BlossomImageProps
 */
const LowQualityImage = ImageComponentByQuality("low");

/**
 * Implementation of the Medium Quality Image. Smaller image and lower quality good for small devices.
 * @param props BlossomImageProps
 */
const MediumQualityImage = ImageComponentByQuality("medium");

/**
 * Implementation of the High Quality Image. Smaller image and lower quality good for small devices.
 *  * @param props BlossomImageProps
 */
const HighQualityImage = ImageComponentByQuality("high");

const allowListImageType = /^.*\.(jpg|JPG|gif|GIF|jpeg|JPEG|svg|SVG|webp|png|PNG)$/;

/**
 * Returns a dimension object based on the width and the image ratio.
 *
 * @return ImageDimension object based on the image ratio and quality
 */
export const dimensionForRatio = (
  ratio: ImageAspectRatio,
  quality: ImageQuality,
  overrideWidth?: number
): ImageDimension => {
  const width = overrideWidth ?? RatiosMap[ratio].getWidthByQuality[quality];
  const height = RatiosMap[ratio].getHeight(width);

  return { width, height };
};

/**
 * Creates an url that leverages AIM to crop and resize images based on the props
 * https://confluence.expedia.biz/display/COPL/Content+Platform+Media+Image+Derivatives
 * It should only modify urls that are images based on the allowList of image types
 *
 * @param src URL of the original image
 * @param dimension width and height to resize the image
 * @param quality to optimize the image
 * @param noCrop set whether the image should be cropped/optimized or not
 */
export const cropResizeSrc = (
  src: string,
  dimension: ImageDimension,
  quality: ImageQuality,
  noCrop = false
): string => {
  if (!allowListImageType.test(src) || noCrop) {
    return src;
  }

  const url = new URL(src.includes(":") ? src : `https:${src}`);
  url.searchParams.set("impolicy", "fcrop");
  url.searchParams.set("w", `${dimension.width}`);
  url.searchParams.set("h", `${dimension.height}`);
  url.searchParams.set("q", `${aimQualityMap[quality]}`);
  return url.toString();
};

export const alternateCropResizeSrc = (src: string, imageProperties: ImageProperties): string => {
  const url = new URL(src.includes(":") ? src : `https:${src}`);
  url.searchParams.set("impolicy", imageProperties.impolicy);
  url.searchParams.set("rw", imageProperties.rw);
  url.searchParams.set("ra", imageProperties.ra);
  return url.toString();
};

/**
 * Basic props for a BlossomImage component. It extends from the UitkImageProps
 * with properties that are specific to Blossom. It enables a component creator
 * to optimize image urls based on the provided
 */
export interface BlossomImageProps {
  /** Set the image source URL */
  src: string;

  /** Set the image alt text */
  alt: string;

  /** Set the dimensions to crop the original image if the defaults are not optimal */
  cropDimension?: ImageDimension;

  /**
   * Set the aspect ratio of the image
   *
   * @default 16-9
   * */
  imageRatio?: string;

  /** Add CSS class(es) */
  className?: string;

  /**
   * Add a UitkImage placeholder image
   *
   * @default false
   * */
  placeholderImage?: boolean;

  /** Override the default image width to avoid blurry images on small viewports */
  widthSmall?: number;

  /** Override the  default image width to avoid blurry images on medium viewports */
  widthMedium?: number;

  /** Override the default image width to avoid blurry images on large viewports */
  widthLarge?: number;

  /** Set the image quality to use on all viewport sizes */
  quality?: ImageQuality;

  /** Set whether to optimize the image or not */
  noCrop?: boolean;

  /**
   * Set the loading for the image
   *
   * @default "off"
   **/
  lazyLoading?: "lazy" | "eager" | "off";

  /** Additional props for UitkImage */
  additionalProps?: object;
}

/**
 * Abstracts an UitkImage component. Optimized images make use of AIM for resizing and cropping.
 * By default all images in Blossom are optimized based on the device but you can disable optimization
 * in cases where the optimization doesn't offer any advantage like an icon.
 */
export const BlossomImage = (props: BlossomImageProps) => {
  const { src } = props;

  if (!src) {
    // Returns an empty image. UitkReact will take care of handling the lack of src appropriately
    return <UitkImage placeholderImage />;
  }

  return (
    <Viewport>
      <ViewSmall>
        <LowQualityImage {...props} />
      </ViewSmall>
      <ViewMedium>
        <MediumQualityImage {...props} />
      </ViewMedium>
      <ViewLarge>
        <HighQualityImage {...props} />
      </ViewLarge>
    </Viewport>
  );
};
